<div class="container">

  <div class="main">

    <app-order-menu [orderId]="orderId"></app-order-menu>

    <hr class="mt-0">

    <h2 class="mt-4 mb-4" i18n="@@unbound-order-log.log-events">Logghändelser</h2>

    <h3 i18n="@@unbound-order-log.order">Beställning</h3>

    <div>
      <div *ngFor="let logEntry of logs" tabindex="0" class="card card-selected p-2">
        <div class="caption-lt-xs">{{logEntry.logText}}</div>
        <div class="caption-md-bl">{{logEntry.createdBy}}</div>
        <div class="caption-lt">
          {{ logEntry.created | amCalendar: {sameElse: dateFormat,nextDay: dateFormat,nextWeek: dateFormat,lastDay: dateFormat,lastWeek: dateFormat} }}
        </div>
      </div>
    </div>
  </div>
</div>
