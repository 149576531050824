import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationEnd, ActivatedRoute } from '@angular/router';
import { Outcome } from '../../common/models/outcome';
import { OrderByService } from '../../common/services/order-by.service';
import { OutcomeService } from '../../common/services/outcome.service';
import { UserRoles } from '../../authentication/user-roles';
import { AuthService } from '../../common/services/auth.service';
import { OutcomeState } from '../../common/models/outcome-state';
import { OperatorService } from '../../common/services/operator.service';
import { Subscription } from 'rxjs';
import { roundOffMinutes } from '@common/date-extension';
import { DatePickerService } from '@services/date-picker.service';
import { DateRange } from '@models/dateRange';
import { DateFilterOption } from '@services/DateFilterOption';
import { DateOrigin } from '@models/date-origin';

@Component({
  selector: 'app-outcome-list',
  templateUrl: './outcome-list.component.html',
  styleUrls: ['./outcome-list.component.scss']
})
export class OutcomeListComponent implements OnInit, OnDestroy {

  dateOrigin = DateOrigin.Outcome;
  paramsub: Subscription;
  path: string;
  queryStates: number[];
  outcomes: Outcome[];
  OutcomeState = OutcomeState;
  UserRoles = UserRoles;
  dateRangeFilter: DateRange;
  operationServiceSub: Subscription;
  outcomeServiceSub:Subscription;
  dateFilterOptionEnum = DateFilterOption;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private datePickerService: DatePickerService,
    private outcomeService: OutcomeService,
    private operatorService: OperatorService,
    private orderByService: OrderByService) { }

  ngOnInit() {
    this.dateRangeFilter = this.datePickerService.outcomeDateRange;
    this.paramsub = this.route
      .queryParams
      .subscribe(params => {
        if (params['state']) {
          this.queryStates = [+params['state']];
        } else {
          this.queryStates = [OutcomeState.WaitingForApprovalByNobina, OutcomeState.WaitingForApprovalBySubcontractor];
        }
        this.getOutcomes();
      });
    this.router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
        if (val.snapshot.url) {
          this.path = '/' + val.snapshot.url.join('/');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.operationServiceSub)
      this.operationServiceSub.unsubscribe();

    if (this.outcomeServiceSub)
      this.outcomeServiceSub.unsubscribe();
  }

  onDateRangeChanged(dateRange: DateRange): void {
    if (!dateRange && (!this.datePickerService.outcomeFromDate && !this.datePickerService.outcomeToDate)) {
      this.dateRangeFilter = undefined;
    } else {
      this.datePickerService.outcomeDateRange = dateRange;
      this.dateRangeFilter = dateRange;
    }
    this.getOutcomes();
  }

  getOutcomes() {
    if (this.authService.userHasAnyRole([UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]) ) {
      this.operationServiceSub = this.operatorService.getOperators(true).subscribe(operators => {
        const ops = operators.body;
        this.outcomeServiceSub = this.outcomeService.getOutcomeOrders(this.queryStates, ops.map(o => o.id), this.dateRangeFilter)
        .subscribe(
          outcomes => { this.handleResponse(outcomes.body); }
        );
      });
    } else {
      this.outcomeService.getOutcomeSubcontractorOrders(this.queryStates, this.dateRangeFilter)
      .subscribe(
        outcomes => { this.handleResponse(outcomes.body); }
      );
    }
  }

  handleResponse(outcomes: Outcome[]) {
    roundOffMinutes([
      ...outcomes.flatMap(o => o.startTripStop?.time),
      ...outcomes.flatMap(o => o.endTripStop?.time)
    ]);
    this.outcomes = this.orderByService.transform(outcomes, [ 'currentState.stateEnum','startTripStop.time' ], true);
  }

  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }
}
