<div>
  <div class='row g-0'>
    <h1 class='my-5 text-center'>
      <ng-container *ngIf='isHistory' i18n='@@order-list.history'>Historik</ng-container>
      <ng-container *ngIf='!isHistory' i18n='@@order-list.orders'>Beställningar</ng-container>
    </h1>
  </div>
</div>
<div class='row g-0 bg-white'>
  <div class='col-12 d-flex flex-column'>
    <div class='d-flex justify-content-between button-container'>
      <div *ngIf='loadRequiresActionList && !isOperatorTrafikledare' class='table-title d-flex mb-2'
           (click)='toggleRequiredActionList()'>
        <img class='table-title__icon' [class.rotate]='!showRequiresActionList' src='/assets/img/icons/angle-down.svg'
             alt='Icon angle-down' />
        <h2 class='table-title__label'><span i18n='@@order-list.actionRequired'>Kräver åtgärd</span>
          ({{actionRequiredCount}})</h2>
      </div>
      <div *ngIf='loadRequiresActionList && isOperatorTrafikledare' class='table-title cursor d-flex mb-2'
           (click)='toggleRequiredActionList()'>
        <img class='table-title__icon' [class.rotate]='!showRequiresActionList' src='/assets/img/icons/angle-down.svg'
             alt='Icon angle-down' />
        <h2 class='table-title__label'><span i18n='@@order-list.activatingRequired'>Hanteras av Nobina</span> ({{actionRequiredCount}})</h2>
      </div>
      <div class='d-flex flex-wrap me-1' style="height: 38px">
        <button id='new-order-btn'
                *ngIf='userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare,UserRoles.OperatorTrafikledare]) && !isHistory'
                class='btn btn-primary me-3 mb-2' (click)='newOrder()' i18n='@@order-list.new-order'>Ny beställning
        </button>

        <div class='view-icons mb-2'>
          <a routerLink="/orders/ordercard" class="btn btn-light me-1" role="button" data-bs-toggle="button"><i class='fa fa-th'></i></a>
          <a routerLink="/orders/" class="btn btn-light active" role="button" data-bs-toggle="button"><i class='fa fa-bars'></i></a>
        </div>
      </div>
    </div>
    <div class='requireActionsList mb-3'
         [class.requireActionsList__hidden]='!showRequiresActionList'>
      <app-order-list-table [TableItems]='RequireActionsList'></app-order-list-table>
    </div>
    <hr class='w-100' />
    <div class='table-title mt-3'>
      <h2><span i18n='@@order-list.trips'>Turer</span> ({{filteredCount}})</h2>
    </div>
    <form [formGroup]='filterForm' class='form m-4'>
      <div class='row g-0 filter-row'>
        <div class='col col-sm-7 d-flex first-column'>
          <input type='text' placeholder='Utförare' i18n-placeholder='@@order-list.subcontractor-placeholder'
                 formControlName='subContractor' (keyup)='searchSubContractor($event.target.value)'
                 class='form-control floating' [(ngModel)]='subContractor'>
          <input type='text' placeholder='Tågnummer' i18n-placeholder='@@order-list.trainnumber-placeholder'
                 formControlName='trainNumber' (keyup)='searchTrainNumber($event.target.value)'
                 class='form-control floating' [(ngModel)]='trainNumber'>
          <ng-select #operatorsSelect [items]='Operators' bindLabel='name' bindValue='id' [multiple]='true'
                     placeholder='Trafikoperatörer' i18n-placeholder='@@order-list.trafficoperator-placeholder'
                     formControlName='selectedOperatorIds' [(ngModel)]='selectedOperators'
                     (change)='onSelectOperator()'>
          </ng-select>
          <select id='orderListType' formControlName='orderListType' class=' select form-control form-select'
                  (change)='selectedOrderListType()'>
            <option *ngFor='let orderListType of orderListTypes' [ngValue]='orderListType.id'>
              {{orderListType.name}}
            </option>
          </select>
        </div>

        <div class='col col-sm-5'>
          <app-date-filter (dateRange)='onDateRangeChanged($event)' (clean)='clearFilter()'
                            [dateOrigin]="dateOrigin" [defaultRangeOption]="dateFilterOptionEnum.Today" [isFuture]='true'>
          </app-date-filter>
        </div>
        <div class='col-12 mt-3'>
          <span class='filter-result'>{{filteredCount}}</span><span class='ms-1'
                                                                    i18n='@@order-list.result'>resultat</span>
        </div>
      </div>
    </form>

    <div class='row g-0'>
      <div class='col-md-12'>
        <div class='table-responsive'>
          <table class='table custom-table datatable' aria-label='Order list'>
            <thead>
            <tr>
              <th class='column_s cursor' (click)='setSortBy($event, 1)'>
                <ng-container i18n='@@order-list.order'>Beställning</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_xs cursor' (click)='setSortBy($event, 2)'>
                <ng-container i18n='@@order-list.trip'>Tur</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_s cursor' (click)='setSortBy($event, 3)'>
                <ng-container i18n='@@order-list.operator'>Operatör</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_xs cursor' (click)='setSortBy($event, 10)'>
                <ng-container i18n='@@order-list.train'>Tåg</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 5)'>
                <ng-container i18n='@@order-list.startTripStop'>Från</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_m cursor' (click)='setSortBy($event, 0)'>
                <ng-container i18n='@@order-list.startTime'>Avgångstid</ng-container>
                <img class='arrow arrow-down' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 6)'>
                <ng-container i18n='@@order-list.endTripStop'>Till</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_m cursor' (click)='setSortBy($event, 4)'>
                <ng-container i18n='@@order-list.endTime'>Ankomsttid</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_xxs'>
                <i class='fa fa-globe'></i>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 8)'>
                <ng-container i18n='@@order-list.driverName'>Förare</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 8)'>
                <ng-container i18n='@@order-list.driverPhoneNumber'>Förartelefon</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 9)'>
                <ng-container i18n='@@order-list.type'>Typ</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_xl cursor' (click)='setSortBy($event, 7)'>
                <ng-container i18n='@@order-list.subContractor'>Utförare</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='column_l cursor' (click)='setSortBy($event, 11)'>
                <ng-container i18n='@@order-list.orderReason'>Orsak</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='cursor' (click)='setSortBy($event, 12)'>
                <ng-container i18n='@@order-list.referenceId'>Referens</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
              <th class='cursor' (click)='setSortBy($event, 13)'>
                <ng-container i18n='@@order-list.comment'>Kommentar</ng-container>
                <img class='arrow arrow-down hidden' src='/assets/img/icons/right-arrow.png' alt='Icon right-arrow'>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let order of OrderTrips |paginate: {
                            itemsPerPage: pageSize,
                            currentPage: currentPage,
                            totalItems: totalRecord} '>

              <td class='column_s' data-toggle='tooltip' data-placement='bottom' title='{{order.orderId}}'>
                <a [ngClass]="{'white-on-red': !order.isActivated
                    && userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig,UserRoles.BergkvaraTrafikledare])}"
                   routerLink='{{order.orderUrl}}'>{{order.orderId}}</a>
                <span class='badge'>{{order.orderTypeShortString}}</span>
              </td>
              <td class='column_xs' data-toggle='tooltip' data-placement='bottom'
                 title="{{order.tripId === 0 ? '' : order.tripId}}">
                  <a *ngIf='order.tripId !== 0' class='route-link' href="{{getLinkToDriveOrderTrip(order.orderId, order.driveOrderId ,order.tripId)}}">
                   {{order.tripId}}
                  </a>
               </td>
              <td class='column_s' data-toggle='tooltip' data-placement='bottom' title='{{order.operator}}'>
                {{order.operator}}
              </td>
              <td class='column_xs' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.trainNumber}}'>
                {{order.trainNumber}}
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom' title='{{order.startTripStop}}'>
                {{order.startTripStop}}
              </td>
              <td class='column_m' data-toggle='tooltip' data-placement='bottom'
                  title="{{order.startTime | amDateFormat:'YYYY-MM-DD HH:mm' }}">
                {{order.startTime | amDateFormat:'YYYY-MM-DD HH:mm' }}
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.endTripStop}}'>
                {{order.endTripStop}}
              </td>
              <td class='column_m' data-toggle='tooltip' data-placement='bottom'
                  title="{{order.endTime === null ? '' : order.endTime | amDateFormat:'YYYY-MM-DD HH:mm' }}">
                {{order.endTime === null ? '' : order.endTime | amDateFormat:'YYYY-MM-DD HH:mm' }}
              </td>
              <td class='column_xxs' data-toggle='tooltip' data-placement='bottom' i18n-title='@@order-list.position'
                  title='Position'>
                <a routerLink='/order/{{order.orderId}}/trip/{{order.tripId}}/position-history-map'>
                  <i class='fa fa-map-marker'></i>
                </a>
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom'
                  title="{{order.driverFirstName ? order.driverFirstName : ''}} {{order.driverLastName ? order.driverLastName : ''}}">
                {{order.driverFirstName ? order.driverFirstName : ''}} {{order.driverLastName ? order.driverLastName : ''}}
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.driverPhoneNumber}}'>
                {{order.driverPhoneNumber}}
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.vehicleType}}'>
                {{order.vehicleType}}
              </td>
              <td class='column_xl' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.subContractor}}'>
                {{order.subContractor}}
              </td>
              <td class='column_l' data-toggle='tooltip' data-placement='bottom'
                  title='{{order.orderReason}}'>
                {{order.orderReason}}
              </td>
              <td data-toggle='tooltip' data-placement='bottom' title='{{order.referenceId}}'>
                {{order.referenceId}}
              </td>
              <td data-toggle='tooltip' data-placement='bottom' title='{{order.comment}}'>
                <div [innerHtml]="order.comment"></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class='table-pagination d-flex flex-wrap justify-content-end align-items-center bg-white mt-3'>
          <label for='pageRows' class='filter-label me-2' i18n='@@order-list.show-pagesize'>Antal per sida: </label>
          <select id='pageRows' class='select form-control form-select' [(ngModel)]='pageSize'
                  (change)='selectedPageSize($event.target.value)'>
            <option>10</option>
            <option>25</option>
            <option>50</option>
          </select>
          <span class='mx-4'>
            <span class='filter-result'>{{ filteredCount }}</span> resultat
          </span>
          <pagination-controls class='pagination me-3' [responsive]='true' (pageChange)='pageChanged($event)'
                               previousLabel='' nextLabel='' autoHide='true'>
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
