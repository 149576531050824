
// Google Maps Javscript API. Man kan istället använda AGM (Angular Google Maps) för att kunna använda sig av Angular-features.
/// <reference types="googlemaps" />

import { Component, OnInit, Input } from '@angular/core';

import { ViewChild } from '@angular/core';

import * as moment from 'moment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DatePipe } from '../../../node_modules/@angular/common';

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss']
})
export class GooglemapComponent implements OnInit {
  @ViewChild('gmap', {static: true}) gmapElement: any;
  map: google.maps.Map;
  private _defaultZoom = 12;
  private _autoZoom = true;
  private _vehicleFeatures = [];
  private _routeFeatures = [];

  private _includeGpsPoints = false;
  private _showingGpsPoints = false;
  private _zoomToIncludePoints = 12;

  @Input() set routeGeoData(routeGeoData: any) {
    this.updateRouteData(routeGeoData);
  }

  @Input() set vehicleGeoData(vehicleGeoData: any) {
    this.updateVehicleData(vehicleGeoData);
  }

  @Input() set autoZoom(autoZoom: boolean) {
    this._autoZoom = autoZoom;
    if (this._autoZoom) {
      this.zoomToFitContent();

      if (this.map && this.needToRestyle())
      {
        this.map.data.setStyle(function (feature) {
          return this.setStyle(feature, this.shouldShowGpsPoints())
        })
        this._showingGpsPoints = this.shouldShowGpsPoints();
      }
    }
  }

  @Input() set includeGpsPoints(includeGpsPoints: boolean) {
    this._includeGpsPoints = includeGpsPoints ?? false;
  }

  @Input() disableStreetView: boolean; // defaults to false, meaning streetview icon will be visible unless it's disabled

  @Input() showCloseButton: boolean;

  constructor() { }

  ngOnInit() {

    const mapProp = {
      center: new google.maps.LatLng(59.327116, 18.072479),
      zoom: this._defaultZoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: !this.disableStreetView,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT
      }
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const self = this;

    this.map.data.setStyle(function (feature) {
      return self.setStyle(feature, self.shouldShowGpsPoints());
    });

    self._showingGpsPoints = self.shouldShowGpsPoints();

    const infowindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -10)
    });

    this.map.data.addListener('mouseover', function(event) {

      const timestamp = event.feature.getProperty('time');
      if (timestamp) {
        infowindow.setContent(moment(timestamp).format('HH:mm:ss'));
        infowindow.setPosition(event.latLng);
        infowindow.open(self.map);
      }
    });

    this.map.data.addListener('mouseout', function(event) {
      infowindow.close();
    });

    if (this.showCloseButton) {
      this.addCloseButton();
     }

    self.map.addListener('zoom_changed', function(event) {
      if (self.needToRestyle())
      {
        self.map.data.setStyle(function (feature) {
          return self.setStyle(feature, self.shouldShowGpsPoints())
        })
        self._showingGpsPoints = self.shouldShowGpsPoints();
      }
    });
  }

  shouldShowGpsPoints(): boolean {
    return this._includeGpsPoints && this.map.getZoom() >= this._zoomToIncludePoints;
  }

  needToRestyle(): boolean {
    return this.shouldShowGpsPoints() !== this._showingGpsPoints;
  }

  setStyle(feature: google.maps.Data.Feature, showGpsPoints?: boolean) {
    const type = feature.getProperty('type');
      let title = '';
      let color = '';
      let icon = '';
      let strokeWeight = 1;
      let zIndex = 0;
      if (type === 'road') {
        color = 'blue';
        strokeWeight = 3;
        zIndex = 10;
      } else if (type === 'stop') {
        icon = '/assets/img/icons/busstop-active.png';
        const lblPlaceId = $localize`:Hållplats@@googlemap.place-id:Hållplats`
        const name = feature.getProperty('name');
        if (name) {
          title = lblPlaceId + ': ' + name;
        } else {
          title = lblPlaceId + ': ' + feature.getProperty('placeId');
        }

        zIndex = 20;
      } else if (type === 'place') {
        icon = '/assets/img/icons/busstop-active.png';
        const lblHastusId = $localize`:HastusId@@googlemap.hastus-id:HastusId`

        title = lblHastusId + ': ' + feature.getProperty('hastusId');
        zIndex = 20;
      } else if (type === 'vehicle') {
        icon = '/assets/img/icons/bus-yellow.png';
        const time = moment(feature.getProperty('time')).local().format('YYYY-MM-DD HH:mm:ss');


        const myTimeInAny = moment(feature.getProperty('time')).format('YYYY/MM/DD/HH/mm/ss');
        const dateArr = myTimeInAny.split('/');
        const localTime = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]), Number(dateArr[3]), Number(dateArr[4]), Number(dateArr[5]));
        const datePipe = new DatePipe('en-US');
        const localTimeFormatted = datePipe.transform(localTime, 'yyyy-MM-dd HH:mm:ss');

        zIndex = 30;
        // const lblVehicle = self.i18n({value: 'Fordon', id: 'googlemap.vehicle'});
        const lblUpdated = $localize`:uppdaterat@@googlemap.updated:uppdaterat`
        // const lblRoute = self.i18n({value: ', linje: ', id: 'googlemap.route'});
        // title = lblVehicle + feature.getProperty('invNo') + lblUpdated + localTimeFormatted + lblRoute + feature.getProperty('route');
        title = feature.getProperty('invNo') + ' ' + lblUpdated + ' ' + localTimeFormatted;
      } else if (type === 'vehiclehistory') {
        color = 'green';
        strokeWeight = 3;
        zIndex = 15;
      } else if (type === 'startPlace') {
        icon = '/assets/img/icons/busstop-start.svg';
        const myTimeInAny = moment(feature.getProperty('startTime')).format('YYYY/MM/DD/HH/mm/ss');
        const dateArr = myTimeInAny.split('/');
        const localTime = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]), Number(dateArr[3]), Number(dateArr[4]), Number(dateArr[5]));
        const datePipe = new DatePipe('en-US');
        const localTimeFormatted = datePipe.transform(localTime, 'yyyy-MM-dd HH:mm:ss');
        title = localTimeFormatted;
        zIndex = 40;
      } else if (type === 'endPlace') {
        icon = '/assets/img/icons/busstop-end.png';
        const myTimeInAny = moment(feature.getProperty('endTime')).format('YYYY/MM/DD/HH/mm/ss');
        const dateArr = myTimeInAny.split('/');
        const localTime = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]), Number(dateArr[3]), Number(dateArr[4]), Number(dateArr[5]));
        const datePipe = new DatePipe('en-US');
        const localTimeFormatted = datePipe.transform(localTime, 'yyyy-MM-dd HH:mm:ss');
        title = localTimeFormatted;
        zIndex = 50;
      } else {
        // position timestamp
        if (this._includeGpsPoints && showGpsPoints) {
          return {
            icon: {
              url: '/assets/img/icons/green-dot.svg',
              anchor: new google.maps.Point(3, 3),
              scaledSize: new google.maps.Size(6, 6),
            },
          };
        }
        icon = '/assets/img/icons/10x10-transparent.png';
        zIndex =  100;
      }

      return {
        icon: { url: icon, scaledSize: new google.maps.Size(25, 25) },
        fillColor: color,
        strokeColor: color,
        strokeWeight: strokeWeight,
        title: title,
        zIndex: zIndex
      };
  }

  updateGeoData(geodata: any) {
    let addedFeatures = [];
    if (geodata !== null && geodata.features !== null && geodata.features.length) {
      addedFeatures = this.map.data.addGeoJson(geodata);
      if (this._autoZoom) {
        this.zoomToFitContent();

        if (this.map && this.needToRestyle())
        {
          this.map.data.setStyle(function (feature) {
            return this.setStyle(feature, this.shouldShowGpsPoints())
          })
          this._showingGpsPoints = this.shouldShowGpsPoints();
        }
      }
    }
    return addedFeatures;
  }

  updateRouteData(geodata: any) {
    this._routeFeatures.forEach(feature => {
      this.map.data.remove(feature);
    });
    this._routeFeatures = this.updateGeoData(geodata);
  }

  updateVehicleData(geodata: any) {
    this._vehicleFeatures.forEach(feature => {
      this.map.data.remove(feature);
    });
    this._vehicleFeatures = this.updateGeoData(geodata);
  }

  zoomToFitContent() {
    if (this.map && this.map.data) {
      const bounds = new google.maps.LatLngBounds();
      this.map.data.forEach(function (feature) {
        feature.getGeometry().forEachLatLng(function (latlng) {
          bounds.extend(latlng);
        });
      });
      this.map.fitBounds(bounds);
      const zoom = this.map.getZoom();
      this.map.setZoom(zoom > this._defaultZoom ? this._defaultZoom : zoom);
    }
  }

  addCloseButton() {
    const controlDiv = document.createElement('div');

    const firstChild = document.createElement('button');
    firstChild.style.backgroundColor = '#f2af32';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '60px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0';
    controlDiv.appendChild(firstChild);

    const secondChild = document.createElement('div');
    secondChild.style.width = '40px';
    secondChild.style.height = '40px';
    secondChild.style.backgroundImage = 'url(/assets/img/icons/times-solid.svg)';
    secondChild.style.backgroundSize = '40px 40px';
    secondChild.style.backgroundRepeat = 'no-repeat';
    firstChild.appendChild(secondChild);

    google.maps.event.addListener(this.map, 'close', function () {
        secondChild.style['background-position'] = '0 0';
    });

    firstChild.addEventListener('click', function () {
        window.history.back();
    });
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);
  }
}
