import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
  HttpErrorResponse, HttpHeaders,
} from '@angular/common/http';

import { ApplicationInsightsService } from '@services/application-insights.service';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { environment } from '../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  locale: string;

  constructor(private appInsightsService: ApplicationInsightsService,
              private alertService: AlertService,
              private authService: AuthService,
              private msalService: MsalService) {
    this.locale = environment.language;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let requestWithHeaders = request.clone({ headers: request.headers.set('accept-language', this.locale) });
    if (!(request.body instanceof FormData)) {
      requestWithHeaders = requestWithHeaders.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(requestWithHeaders)
      .pipe(
        catchError((err: HttpErrorResponse) => {

          const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' } as const;
          const now = new Date();
          const timestamp = now.toLocaleDateString(this.locale, options);

          if (err.status === 200) {
            // Not an error, catchError was misstaken, continue as usual
            return next.handle(requestWithHeaders);
          }
          if (err.status === 0) {
            // A client-side or network error occurred.
            this.alertService.error(`Ingen kontakt med servern. ${timestamp}`);
          } else if (err.status === 401) {
            return this.refreshAndRetry(next, requestWithHeaders).pipe(
              catchError((retryError: HttpErrorResponse) => {
                this.alertService.error(`Behörighet saknas (status ${err.status}). ${timestamp}`);
                return throwError(() => retryError);
              }
            ));
          } else if (err.status === 403) {
            this.alertService.error(`Behörighet saknas (status ${err.status}). ${timestamp} ${err.error != null ? ' Fel-ID: ' + err.error.operationId : ''}`);
          } else {
            this.alertService.error(err.error != null ? err.error.errorMessage + '. ' + timestamp + ' Fel-ID: ' + err.error.operationId : `Okänt fel. ${timestamp}`);
          }
          this.logError(err);

          // Will be handled by GlobalErrorHandling
          return throwError(() => err);
        }));
  }

  refreshAndRetry(next: HttpHandler, requestWithHeaders: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.msalService.acquireTokenSilent(
      {
        forceRefresh: true,
        scopes: this.getScopeForRequest(requestWithHeaders),
        account: this.msalService.instance.getActiveAccount(),
      },
    ).pipe(
      switchMap((result: AuthenticationResult) => {
        const clonedRequest = this.updateHeadersForRetry(requestWithHeaders, result.accessToken);
        return next.handle(clonedRequest);
      }),
    );
  }

  updateHeadersForRetry(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    let newHeaders = new HttpHeaders();
    request.headers.keys().forEach((key) => {
      const val = request.headers.get(key);
      if (key === 'Authorization') {
        newHeaders = newHeaders.set(key, `Bearer ${accessToken}`);
      } else {
        newHeaders = newHeaders.set(key, val);
      }
    });
    return request.clone({ headers: newHeaders });
  }

  getScopeForRequest(request: HttpRequest<any>): string[] {
    if (request.url.includes(environment.apiUrl)) {
      return environment.b2c.mainServiceScopes;
    } else if (request.url.includes(environment.garageUrl)) {
      return environment.b2c.garageServiceScopes;
    } else if (request.url.includes(environment.outcomeUrl)) {
      return environment.b2c.outcomeServiceScopes;
    } else if (request.url.includes(environment.positionUrl)) {
      return environment.b2c.iotFunctionsServiceScopes;
    } else {
      return [];
    }
  }

  logError(err: HttpErrorResponse) {
    const error = new Error(err.name);
    error.message = err.message;

    const properties: { [name: string]: string } = {};
    properties['message'] = err.message;
    properties['name'] = err.name;
    properties['status'] = err.status + '';
    properties['statusText'] = err.statusText;
    properties['url'] = err.url;
    if (err.error) {
      properties['errorCode'] = err.error.errorCode;
      properties['errorMessage'] = err.error.errorMessage;
      this.appInsightsService.setOperationId(err.error.operationId);
    }
    properties['user.profile.email'] = this.authService.user.idTokenClaims.email as string;
    properties['user.profile.roles'] = this.authService.user.idTokenClaims.role.toString();
    this.appInsightsService.logException(error, undefined, properties);
    console.error(`Backend returned status ${err.status},
      url: ${err.url != null ? err.url : ''},
      errorCode: ${err.error != null ? err.error.errorCode : ''},
      errorMessage: ${err.error != null ? err.error.errorMessage : ''},
      operationId: ${err.error != null ? err.error.operationId : ''}`);
  }

}

