import { Component, OnInit } from '@angular/core';
import { LogService } from '../../../common/services/log.service';
import { ActivatedRoute } from '@angular/router';
import { LogResponse } from '../../../common/models/log';

@Component({
  selector: 'app-unbound-order-log',
  templateUrl: './unbound-order-log.component.html',
  styleUrls: ['./unbound-order-log.component.scss']
})
export class UnboundOrderLogComponent implements OnInit {
  logs: LogResponse[];
  orderId: number;
  dateFormat = 'YYYY-MM-DD HH:mm';

  constructor(private logService: LogService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.orderId = +this.route.snapshot.paramMap.get('id');

    this.logService.getUnboundOrderLogs(this.orderId)
      .subscribe(logs => this.onLogsRetrieved(logs.body));
  }

  onLogsRetrieved(logs: LogResponse[]) {
    this.logs = logs;
  }

}
